import { useCallback } from 'react';

export function useRequestHelp() {
	const diagnose = useCallback(() => {
		const a: HTMLScriptElement | null = document.querySelector(
			"script[href='https://spy.ihook.center/page-spy/index.min.js']",
		);
		if (a) {
			return;
		}

		const n: HTMLScriptElement = document.createElement('script');
		n.crossOrigin = 'anonymous';
		n.src = 'https://spy.ihook.center/page-spy/index.min.js';

		document.head.appendChild(n);

		// @ts-ignore
		window.$pageSpy = new PageSpy();
	}, []);

	return diagnose;
}
